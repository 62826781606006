import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import FeatureCard from '../components/feature-card';
import GalleryCard3 from '../components/gallery-card3';
import Question from '../components/question';
import './home.css';

const Home = (props) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Kash Courier Services</title>
        <meta
          name="description"
          content="At Kash Courier Services, we take pride in our commitment to excellence. With experience in the logistics industry, we've built a reputation for reliability, efficiency, and customer satisfaction."
        />
        <meta property="og:title" content="Kash Courier Services" />
        <meta
          property="og:description"
          content="At Kash Courier Services, we take pride in our commitment to excellence. With experience in the logistics industry, we've built a reputation for reliability, efficiency, and customer satisfaction."
        />
      </Helmet>
      <div data-modal="practices" className="home-modal">
        <div className="home-practices">
          <div className="home-heading">
            <span className="home-header">Our practices</span>
            <svg
              viewBox="0 0 1024 1024"
              data-close="practices"
              className="home-close"
            >
              <path d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
            </svg>
          </div>
          <div className="home-grid">
            <div className="home-section">
              <div className="home-heading1">
                <span className="home-header1">Cardiology</span>
                <span className="home-caption">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt.
                </span>
              </div>
              <div className="read-more">
                <span className="home-text">Read more</span>
                <img
                  alt="image"
                  src="/external/arrow-2.svg"
                  className="home-image"
                />
              </div>
            </div>
            <div className="home-section1">
              <div className="home-heading2">
                <span className="home-header2">Orthopedics</span>
                <span className="home-caption1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt.
                </span>
              </div>
              <div className="read-more">
                <span className="home-text01">Read more</span>
                <img
                  alt="image"
                  src="/external/arrow-2.svg"
                  className="home-image01"
                />
              </div>
            </div>
            <div className="home-section2">
              <div className="home-heading3">
                <span className="home-header3">Ophtalmology</span>
                <span className="home-caption2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt.
                </span>
              </div>
              <div className="read-more">
                <span className="home-text02">Read more</span>
                <img
                  alt="image"
                  src="/external/arrow-2.svg"
                  className="home-image02"
                />
              </div>
            </div>
            <div className="home-section3">
              <div className="home-heading4">
                <span className="home-header4">Pediatrics</span>
                <span className="home-caption3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt.
                </span>
              </div>
              <div className="read-more">
                <span className="home-text03">Read more</span>
                <img
                  alt="image"
                  src="/external/arrow-2.svg"
                  className="home-image03"
                />
              </div>
            </div>
            <div className="home-section4">
              <div className="home-heading5">
                <span className="home-header5">Nutrition</span>
                <span className="home-caption4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt.
                </span>
              </div>
              <div className="read-more">
                <span className="home-text04">Read more</span>
                <img
                  alt="image"
                  src="/external/arrow-2.svg"
                  className="home-image04"
                />
              </div>
            </div>
            <div className="home-section5">
              <div className="home-heading6">
                <span className="home-header6">General</span>
                <span className="home-caption5">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt.
                </span>
              </div>
              <div className="read-more">
                <span className="home-text05">Read more</span>
                <img
                  alt="image"
                  src="/external/arrow-2.svg"
                  className="home-image05"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="home-hero">
        <header data-thq="thq-navbar" className="home-navbar">
          <div className="home-left">
            <a href="#HOME" className="home-link">
              <img
                alt="image"
                src="/orange%20modern%20cargo%20delivery%20company%20logo-400w.webp"
                className="home-logo"
              />
            </a>
            <nav className="home-links">
              <a href="#FEATURES" className="home-link1">
                Features
              </a>
              <a href="#SERVICE" className="home-link2">
                Services
              </a>
              <a href="#ADBOUT" className="home-link3">
                About US
              </a>
              <a href="#CONTACT" className="home-link4">
                Contact US
              </a>
            </nav>
          </div>
          <div data-thq="thq-navbar-btn-group" className="home-right">
            <button className="home-phone button">
              <img
                alt="image"
                src="/external/phone.svg"
                className="home-image06"
              />
              <span className="home-text06">02136906633</span>
            </button>
            <a
              href="https://wa.me/+923378216146"
              target="_blank"
              rel="noreferrer noopener"
              className="home-book button button-main"
            >
              <img
                alt="image"
                src="/external/calendar.svg"
                className="home-image07"
              />
              <span className="home-text07">Book an appointment</span>
            </a>
          </div>
          <div
            data-thq="thq-burger-menu"
            className="home-burger-menu"
            onClick={toggleMobileMenu}
          >
            <svg viewBox="0 0 1024 1024" className="home-icon1">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
        data-thq="thq-mobile-menu"
        className={`home-mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}
      >
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="home-nav"
            >
              <div className="home-container01">
              <a href="#HOME" className="home-link">
              <img
                alt="image"
                src="/orange%20modern%20cargo%20delivery%20company%20logo-400w.webp"
                className="home-logo"
              />
            </a>
                <div
          data-thq="thq-close-menu"
          className="home-menu-close"
          onClick={toggleMobileMenu}
        >
          <svg viewBox="0 0 1024 1024" className="home-icon3">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav data-thq="thq-mobile-menu-nav-links" data-role="Nav">
          <a
            href="#FEATURES"
            className="home-link1"
            onClick={() => closeMobileMenu()}
          >
            Features
          </a>s
          <br />
          <a
            href="#SERVICE"
            className="home-link2"
            onClick={() => closeMobileMenu()}
          >
            Services
          </a>
          <br />
          <a
            href="#ADBOUT"
            className="home-link3"
            onClick={() => closeMobileMenu()}
          >
            About US
          </a>
          <br />
          <a
            href="#CONTACT"
            className="home-link4"
            onClick={() => closeMobileMenu()}
          >
            Contact US
          </a>
        </nav>
            </div>
          </div>
        </header>
        <div id="HOME" className="home-main">
          <div className="home-content">
            <div className="home-heading7">
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <h1 className="home-header7">Kash Courier Services</h1>
              <p className="home-caption6">
                <span className="home-text13">
                  Your Trusted Partner in Swift and Secure Deliveries
                </span>
                <br className="home-text14"></br>
                <br className="home-text15"></br>
                <span className="home-text16">
                  Welcome to Kash Courier Services, where reliability meets
                  efficiency. We specialize in providing swift, secure, and
                  cost-effective courier solutions tailored to your needs.
                  Whether it&apos;s urgent documents, packages, or delicate
                  items, we ensure timely deliveries with a commitment to
                  excellence. Experience peace of mind with Kash Courier
                  Services, your dependable logistics partner.
                </span>
              </p>
            </div>
            <br></br>
            <a
              href="https://wa.me/+923378216146"
              target="_blank"
              rel="noreferrer noopener"
              className="home-book2 button button-main"
            >
              <img
                alt="image"
                src="/external/calendar.svg"
                className="home-image10"
              />
              <span>Book an appointment</span>
            </a>
          </div>
          <div className="home-image11">
            <img
              alt="image"
              src="/external/pngwing.com-500w-500w.webp"
              className="home-image12"
            />
          </div>
        </div>
       
      </section>
      <div className="home-details">
        <div className="home-details1">
          <div className="home-container02">
            
            <h2 className="home-details-heading heading2">
              About Kash Courier Services
            </h2>
            <span className="home-details-sub-heading">
              Kash Courier Services is a leading provider of courier and
              delivery solutions, catering to both individuals and businesses.
              With our extensive network and dedicated team, we ensure that your
              packages are delivered safely and on time. Our commitment to
              customer satisfaction sets us apart from the competition.
            </span>
          </div>
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1621972750749-0fbb1abb7736?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE0fHxjb3VyaWVyfGVufDB8fHx8MTcwMzg4MjU0Nnww&amp;ixlib=rb-4.0.3&amp;w=400"
            className="home-details-image"
          />
        </div>
      </div>
      <div className="home-features">
        <div className="home-features-container">
          <div id="FEATURES" className="home-features1">
            <div className="home-container03">
              
              <h2 className="home-features-heading heading2">
                Enhance Your Delivery Experience
              </h2>
              <span className="home-features-sub-heading">
                Discover the key features that make Kash Courier Services the
                best choice for all your delivery needs.
              </span>
            </div>
            <div className="home-container04">
              <FeatureCard
                heading="Real-time Tracking"
                subHeading="Track your package in real-time and know its exact location at any given moment."
              ></FeatureCard>
              <FeatureCard
                heading="Secure Delivery"
                subHeading="Rest assured that your packages are handled with utmost care and delivered securely."
              ></FeatureCard>
              <FeatureCard
                heading="Flexible Scheduling"
                subHeading="Choose a convenient pickup and delivery time that fits your schedule."
              ></FeatureCard>
              <FeatureCard
                heading="Competitive Pricing"
                subHeading="Enjoy affordable rates for reliable and efficient courier services."
              ></FeatureCard>
            </div>
          </div>
        </div>
      </div>
      <div className="home-gallery">
        <div id="SERVICE" className="home-gallery1">
          <h1 className="home-gallery-heading heading2">Our Services</h1>
          <span className="home-gallery-sub-heading">
            <span className="home-text24">
              Express Delivery: Urgent, time-sensitive deliveries with priority
              handling.
            </span>
            <br className="home-text25"></br>
            <span className="home-text26">
              Standard Delivery: Reliable and cost-effective regular delivery
              services.
            </span>
            <br className="home-text27"></br>
            <span className="home-text28">
              Special Handling: Delicate or high-value items handled with utmost
              care.
            </span>
            <br className="home-text29"></br>
            <span className="home-text30">
              International Shipping: Seamless global deliveries, ensuring
              worldwide reach.
            </span>
            <br className="home-text31"></br>
            <span className="home-text32">
              Customized Solutions: Tailored services to match unique delivery
              requirements.
            </span>
          </span>
          <div className="home-container05">
            <GalleryCard3
              imageSrc="https://images.unsplash.com/photo-1566576721346-d4a3b4eaeb55?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fGNvdXJpZXJ8ZW58MHx8fHwxNzAzODgyNTQ2fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
              rootClassName="rootClassName"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="https://images.unsplash.com/photo-1620455800201-7f00aeef12ed?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fGNvdXJpZXJ8ZW58MHx8fHwxNzAzODgyNTQ2fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
              rootClassName="rootClassName1"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="https://images.unsplash.com/photo-1607227063002-677dc5fdf96f?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDR8fGNvdXJpZXJ8ZW58MHx8fHwxNzAzODgyNTQ2fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
              rootClassName="rootClassName3"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="https://images.unsplash.com/photo-1595054225874-7d2315262e73?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE4fHxjb3VyaWVyfGVufDB8fHx8MTcwMzg4MjU0Nnww&amp;ixlib=rb-4.0.3&amp;w=1500"
              rootClassName="rootClassName2"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="https://images.unsplash.com/photo-1612630741022-b29ec17d013d?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDZ8fGNvdXJpZXJ8ZW58MHx8fHwxNzAzODgyNTQ2fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
              rootClassName="rootClassName4"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="https://images.unsplash.com/photo-1446694292248-2c2a7e575b1b?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcwMzg4NzcwNHw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=400"
              rootClassName="rootClassName5"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="https://images.unsplash.com/photo-1665689552137-7057101c7a6d?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcwMzg4NzcwNHw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=400"
              rootClassName="rootClassName6"
            ></GalleryCard3>
            <GalleryCard3
              imageSrc="https://images.unsplash.com/photo-1604605801370-3396f9bd9cf0?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE5fHxjb3VyaWVyfGVufDB8fHx8MTcwMzg4MjU0Nnww&amp;ixlib=rb-4.0.3&amp;w=1500"
              rootClassName="rootClassName7"
            ></GalleryCard3>
          </div>
        </div>
      </div>
      <div id="ADBOUT" className="home-hero1">
        <div className="home-heading8">
          <h1 className="home-header8">About Us</h1>
          <p className="home-caption7">
            <span>
              Welcome to Kash Couriers, your trusted partner in parcel delivery
              excellence! As the CEO of Kash Couriers and embracing my
              Pakistani-Irish heritage, I am thrilled to bring a seamless and
              reliable courier service to the bustling city of Karachi,
              Pakistan.
            </span>
            <br className="home-text34"></br>
            <br className="home-text35"></br>
            <span>
              At Kash Couriers, we understand the importance of swift and secure
              deliveries. Our mission is to redefine courier services by
              providing not just logistics, but a commitment to reliability and
              customer satisfaction. With a blend of cutting-edge technology and
              personalized care, we aim to be the go-to choice for all your
              parcel delivery needs.
            </span>
            <br className="home-text37"></br>
            <br className="home-text38"></br>
            <span>
              Thank you for choosing Kash Couriers. Whether it&apos;s a package
              or a promise, we deliver with dedication. Experience the
              difference with Kash Couriers – where your parcels are not just
              deliveries, but a testament to our commitment to excellence.&quot;
            </span>
            <br className="home-text40"></br>
            <br className="home-text41"></br>
            <span>
              Kash Malik
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="home-text43"></br>
            <span>CEO &amp; Founder</span>
          </p>
        </div>
        <img
          alt="image"
          sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
          src="/whatsapp%20image%202024-02-06%20at%2001.51.29_6e78b6f1-1500h.webp"
          srcSet="/whatsapp%20image%202024-02-06%20at%2001.51.29_6e78b6f1-1500h.webp 1200w, /whatsapp%20image%202024-02-06%20at%2001.51.29_6e78b6f1-tablet.webp 800w, /whatsapp%20image%202024-02-06%20at%2001.51.29_6e78b6f1-mobile.webp 480w"
          className="home-image13"
        />
      </div>
      <h1>
        <br></br>
        <span className="home-text47">
          Our Team
        </span>
        <br></br>
        <br></br>
      </h1>
      <img
        alt="image"
        sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
        src="/untitled%20design-1500w.webp"
        srcSet="/untitled%20design-1500w.webp 1200w, /untitled%20design-tablet.webp 800w, /untitled%20design-mobile.webp 480w"
        className="home-image14"
      />
      <div className="home-banner">
        <div id="CONTACT" className="home-banner1">
          <h1 id="Contact" className="home-banner-heading heading2">
            Contact
          </h1>
          <span className="home-banner-sub-heading">
            <span className="home-text50">
              Have a question or need assistance? Our customer service team is
              here to help. Reach out to us via phone, email, or visit our
              office during business hours. Let Kash Courier Services be your
              trusted logistics partner for all your delivery needs.
            </span>
            <br className="home-text51"></br>
            <br className="home-text52"></br>
            <span className="home-text53">Phone</span>
            <br className="home-text54"></br>
            <span>+923378216146</span>
            <br className="home-text58"></br>
            <br className="home-text59"></br>
            <span className="home-text60">Email</span>
            <br></br>
            <span>info@kashcourierservices.com</span>
            <br></br>
            <br></br>
            <span className="home-text65">Address</span>
            <br></br>
            <span>
              Erum Shopping Mall Phase-I, Sector 15-A, Karachi, Pakistan
            </span>
            <br></br>
          </span>
        </div>
      </div>
      <div className="home-faq">
        <div className="home-faq-container">
          <div className="home-faq1">
            <div className="home-container06">
              <span className="home-text69 sectionTitle">
                <span>FAQ</span>
                <br></br>
              </span>
              <h2 className="home-text72 heading2">Common questions</h2>
              <span className="home-text73">
                <span>
                  Here are some of the most common questions that we get.
                </span>
                <br></br>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="home-container07">
              <Question
                answer="Kash courier offers a wide range of services including same-day delivery, next-day delivery, international shipping, and package tracking."
                question="What services does Kash courier offer?"
              ></Question>
              <Question
                answer="You can easily track your package by entering the tracking number provided to you at the time of booking on our website's tracking page."
                question="How can I track my package?"
              ></Question>
              <Question
                answer="For domestic shipments, we offer same-day delivery within city limits and next-day delivery for intercity shipments."
                question="What are the delivery timeframes for domestic shipments?"
              ></Question>
              <Question
                answer="Yes, we provide insurance coverage for packages based on their declared value. Additional charges may apply."
                question="Do you provide insurance for packages?"
              ></Question>
              <Question
                answer="We accept payment through major credit cards, debit cards, and online payment platforms such as PayPal."
                question="What are the accepted payment methods?"
              ></Question>
            </div>
          </div>
        </div>
      </div>
      <div className="home-footer">
        <footer className="home-footer1">
          <div className="home-container08">
            <span className="home-logo1">KASH</span>
            <nav className="home-nav2 home-nav2">
              <a href="#HOME" className="home-nav1 home-nav1">Home</a>
              &nbsp;&nbsp;&nbsp;<a href="#SERVICE">Services</a>
              <a href="#FEATURES" className="home-nav3">Features</a>
              <a href="#ADBOUT" className="home-nav4">About Us</a>
              <a href="#CONTACT" className="home-nav5">Contact Us</a>
            </nav>
          </div>
          <div className="home-separator"></div>
          <div className="home-container09">
            <span className="home-text90">
              © 2023 myCompany, All Rights Reserved.
            </span>
            <div className="home-icon-group">
              <a
                href="https://www.instagram.com/kashcourierservices?igsh=MWs4MHMwMHd5ZmZjNw%3D%3D&amp;utm_source=qr"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link5"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon5"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61555044579787&amp;mibextid=LQQJ4d"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link6"
              >
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="home-icon7"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Home
